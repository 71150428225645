import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description = '', meta = [], title, canonicalLink, image }) {
    const { site, twitter, previewImage } = useStaticQuery(
        graphql`
        query {
            previewImage: file(name: {eq: "avatar"}) {
                childImageSharp {
                    fixed(width: 1200, height: 1200) {
                        src
                        srcWebp
                    }
                }
            }
            site {
                siteMetadata {
                    title
                    description
                    siteUrl
                }
            }
            twitter: socialMediaYaml(name: {eq: "Twitter"}) {
                link
                tag
            }
        }
        `
    );

    const useBigImage = !!image;
    const previewImageSource = image || previewImage.childImageSharp.fixed;

    const prefix = site.siteMetadata.siteUrl;
    const metaDescription = description || site.siteMetadata.description
    const fullTitle = `${title} | ${site.siteMetadata.title}`;

    return (
        <Helmet
            htmlAttributes={{
                lang: 'en',
            }}
            title={fullTitle}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: fullTitle,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: `${prefix}${previewImageSource.src}`
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: useBigImage ? `summary_large_image` : `summary`,
                },
                {
                    name: `twitter:image`,
                    content: `${prefix}${previewImageSource.srcWebp}`
                },
                {
                    name: `twitter:creator`,
                    content: twitter.tag,
                },
                {
                    name: `twitter:title`,
                    content: fullTitle,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ].concat(meta)}

            link={[].concat(
              canonicalLink
                ? {
                    rel: `canonical`,
                    href: canonicalLink,
                  }
                : []
            )}
        />
    )
}

export default SEO;
